import React from 'react'
import { getDistributionGroups } from '../../utils/email'
import * as style from '../Form/form.module.css'

class DistributionListSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groups: []
    }
  }

  componentDidMount() {
    getDistributionGroups(function(err, groups){
      if(err){
        console.log(err)
      }
      if(!groups){
        groups = []
      }
      this.setState({
        groups: groups
      })
      if(groups[0] && this.props.listId === ""){
        this.setState({
          selectedGroupId: groups[0].Id
        })
        // this.props.onChange(groups[0])
      }else if(this.props.listId){
        this.setState({
          selectedGroupId: this.props.listId
        })
      }
    }.bind(this))
  }

  onChange = (event) => {
    let newValue = parseInt(event.target.value)
    let selectedGroup = this.state.groups.find(function(group){
      return group.Id === newValue
    });
    this.setState({
      selectedGroupId: newValue
    })
    this.props.onChange(selectedGroup)
  }

  render(){
    if(this.state.groups){
      let groupItems = this.state.groups.map(function(group, index){
        return(
          <option key={group.Id} value={group.Id}>{group.Name} ({group.MembersCount} members)</option>
        )
      })
      return(<select className={style['form__select']} onChange={this.onChange} value={this.props.listId}>{groupItems}</select>)
    }else{
      return(<select className={style['form__select']} onChange={this.onChange} value=' '> </select>)
    }
  }

}

export default DistributionListSelect

import React from 'react'
import Campaign from '../../../components/Email/Campaign'
import FSRoute from '../../../components/FSRoute'
import { isBrowser } from '../../../utils/misc'
const App = ({data}) => {
  if(!isBrowser) return(null)
  return(
    <>
    <FSRoute path="/email/campaign" component={Campaign} fsOnly='false' data={data} />
    </>
  )
}

export default App

import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'

class ShortCodesLink extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lightBoxOpen: false,
    }
  }

  componentDidMount() {

  }

  render() {
    return(
      <div style={{
        textAlign: 'right',
        display: 'inline-block'
      }}>
      <button style={{
        background: 'transparent',
        color: '#666666',
        textDecoration: 'underline',
        outline: 'none',
        marginRight: '0px',
        paddingRight: '0px',
        marginTop: '0px',
        paddingTop: '0px'
      }} onClick={(e) => {e.preventDefault();this.setState({ lightBoxOpen: true})}}>SHORTCODES AVAILABLE</button>
      <Dialog
        className="standard-dialog"
        open={this.state.lightBoxOpen}
        onClose={() => this.setState({ lightBoxOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Available Shortcodes
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <p>Shortcodes can be used to insert recipient data into your message. Just enter the shortcode surrounded by square brackets in your text. You'll be able to view a generic value for the shortcodes in the preview. These will be replaced with the recipient data in each contact for each recipient.</p>
          <p>The following shortcodes are available: </p>
          <ul>
            <li>
              <h4>[ContactFirstName]</h4>
              <p>Inserts just the first name of your contact. For example, if your contact's name is John Smith, the text <strong>[ContactFirstName]</strong> will be replaced with <strong>John</strong></p>
            </li>
            <li>
              <h4>[ContactLastName]</h4>
              <p>Inserts just the last name of your contact. For example, if your contact's name is John Smith, the text <strong>[ContactLastName]</strong> will be replaced with <strong>Smith</strong></p>
            </li>
            <li>
              <h4>[ContactName]</h4>
              <p>Inserts full name of your contact. For example, if your contact's name is John Smith, the text <strong>[ContactName]</strong> will be replaced with <strong>John Smith</strong></p>
            </li>
          </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => this.setState({ lightBoxOpen: false })}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    )
  }
}

export default(ShortCodesLink)

import React from 'react'
import Layout from '../Layout'
import View from '../View'
import moment from 'moment'
import Form from '../Form/email-campaign'
import { globalHistory } from '@reach/router'
import MultiStepForm from '../MultiStepForm'
import FormTab from '../MultiStepForm/FormTab'
import Slider from '../Slider'
import {
  sendCampaign,
  setPageData,
  getEmailTemplate,
  getEmailLog,
  getCampaignLogById,
  getCampaignLogsScheduled,
  getDistributionGroupTargeted,
  getDistributionGroups,
  updateCampaign,
  checkFeatureChange } from '../../utils/email'
import {
  getSiteIndex
} from '../../utils/site-index'
import { parseQuery } from '../../utils/query'
import EmailPreview from '../EmailPreview'
import URLAutocomplete from '../URLAutocomplete'
import Moment from 'moment'
import {
  validURL,
  validateEmail
} from '../../utils/validation'
import * as styles from '../Form/form.module.css'
import done from '../../../static/img/done.jpg'
import { getCurrentUser } from '../../utils/auth'
import { navigate } from '@reach/router'
import WaitLoader from '../WaitLoader'
import RichTextEditor from './RichTextEditor'

require('../Tabs/tabs.module.css')

class EmailCampaign extends React.Component {
  constructor(props) {
    super(props)
    var domain = 'https://www.forms-surfaces.com'
    var pageQuery = parseQuery(globalHistory.location.search)
    if(pageQuery[0]){
      var arr = pageQuery.split('/')
      domain = arr[0] + '//' + arr[2]
    }
    var date = this.getDefaultScheduleDate([])
    var user = getCurrentUser()
    if(user.fsOnly){
      if(user.fsOnly.websiteDomain){
        domain = user.fsOnly.websiteDomain
      }
    }
    this.state = {
      pageTitle: 'CREATE A NEW EMAIL CAMPAIGN',
      actionText: 'SCHEDULE CAMPAIGN',
      siteIndex: [],
      campaignId: null,
      campaignName: '',
      listId: '',
      selectedList: {},
      subject: '',
      userMessage: '',
      showUserImage: false,
      errorMessage: '',
      submitError: '',
      emailBody: '<p>Generating email from template.</p>',
      scheduleDate: date,
      followUpDate: date.add(4, 'weeks'),
      dateError: '',
      random: 0,
      errors: [],
      emailValidationMessage: '',
      isFormValid: false,
      domain: domain,
      template: null,
      overrideTemplate: '',
      loadedTemplate: null,
      query: {
        url: 'https://www.forms-surfaces.com/apex-bench'
      },
      useTemplateOverride: false,
      images: [],
      pages: [],
      recipientRows: 1,
      unsubscribed: [],
      scheduled: [],
      targetedContacts: 0,
      loading: true
    }

  }

  getDefaultScheduleDate = (scheduled) => {
    var scheduleDay = moment()
    scheduleDay.add(1, 'days').set({
      'hour': 11,
      'minute': 0,
      'second': 0
    })
    while(this.checkAlreadyScheduled(scheduleDay, scheduled)){
      scheduleDay.add(1, 'days')
    }
    return scheduleDay
  }

  componentDidMount() {
    getSiteIndex(this.state.domain, function(err, siteIndex){
      if(err){
        this.setState({errorMessage: err.message});
        return
      }
      this.setState({
        siteIndex: siteIndex
      })
    }.bind(this))
    var pageQuery = this.state.query;
    if(globalHistory.location.search){
      pageQuery = parseQuery(globalHistory.location.search)
      if(pageQuery.url){
        var url = pageQuery.url
        var arr = url.split('/')
        var domain = arr[0] + '//' + arr[2]
        this.setState({domain:domain})
        this.setState({ query: pageQuery }, function() {

          this.setState({url:this.state.query.url});
          this.featureChange(this.state.query.url, 0);
          setPageData(this.state.query.url, false, function(err, data){
            if(err){
              this.setState({errorMessage: err.message});
              return
            }
            this.setState({ subject: data.title })
          }.bind(this))

        })
      }
      if(pageQuery.template){
        getDistributionGroups(function(err, groups){
          let selectedList = {}
          if(groups){
            selectedList = groups[0]
            if(!selectedList){
              selectedList = {}
            }
          }
          getEmailTemplate({templateid: pageQuery.template}, function(err, data){
            if(err){
              console.log(err)
            }
            if(data){
              if(data.template){
                if(data.template.errorMessage){
                  this.setState({errorMessage: data.template.errorMessage, loading: false})
                }
                let useTemplateOverride = false
                let overrideTemplate = ''
                if(data.template.overrideTemplate){
                  useTemplateOverride = true
                  overrideTemplate = data.template.overrideTemplate
                }
                this.setTemplate(data.template, function(){
                  this.setState({
                    selectedList: selectedList,
                    listId: selectedList.Id,
                    useTemplateOverride: useTemplateOverride,
                    overrideTemplate: overrideTemplate,
                    loading: false
                  }, function(){
                    this.checkTargeting(selectedList.Id)
                  })
                }.bind(this))
              }
            }
          }.bind(this))
          // this.checkTargeting(response.listId, Moment(response.scheduled), pageQuery.log)
        }.bind(this))
      }

      if(pageQuery.log){
        getCampaignLogById(pageQuery.log, function(err, response){
          if(err){
            console.log(err)
          }
          if(response){
            getDistributionGroups(function(err, groups){
              let selectedList = {}
              if(groups){
                selectedList = groups.find(function(group, i){
                  return parseInt(group.Id) === parseInt(response.listId)
                })
                if(!selectedList){
                  selectedList = {}
                }
              }

              if(response.template){
                getEmailTemplate({templateid: response.template}, function(err, data){
                  if(err){
                    console.log(err)
                  }
                  if(data){
                    if(data.template){
                      if(data.template.errorMessage){
                        this.setState({errorMessage: data.template.errorMessage, loading: false})
                      }
                      let useTemplateOverride = false
                      let overrideTemplate = ''
                      if(data.template.overrideTemplate){
                        useTemplateOverride = true
                        overrideTemplate = data.template.overrideTemplate
                      }
                      this.setState({
                        loadedTemplate: data.template,
                        useTemplateOverride: useTemplateOverride,
                        overrideTemplate: overrideTemplate
                      })
                    }
                  }
                }.bind(this))
              }
              this.checkTargeting(response.listId, Moment(response.scheduled), pageQuery.log)
              this.setState({
                campaignId: response._id,
                pageTitle: 'EDIT EMAIL CAMPAIGN',
                actionText: 'UPDATE CAMPAIGN',
                campaignName: response.name,
                scheduleDate: Moment(response.scheduled),
                subject: response.subject,
                listId: response.listId,
                selectedList: selectedList,
                userMessage: response.userMessage,
                pages: response.pagesShared,
                domain: response.domain,
                showUserImage: response.showUserImage,
                overrideTemplate: response.overrideTemplate,
                template: response.template,
                loading: false
              },function(){
                this.validate()
              })
            }.bind(this))
          }
        }.bind(this))
      }
    }else{
      getDistributionGroups(function(err, groups){
        let selectedList = {}
        if(groups){
          selectedList = groups[0]
        }
        this.setState({
          loading: false,
          selectedList: selectedList,
          listId: selectedList.Id
        }, function(){
          this.checkTargeting(this.state.listId)
        })
      }.bind(this))
    }
  }

  checkTargeting(listId, scheduleDate, logId){
    getCampaignLogsScheduled(function(err, response){
      if(err){
        console.log(err)
        this.setState({errorMessage: err.message, loading: false, scheduled: []})
      }else{
        if(!response){
          response = []
        }
        if(logId){
          response = response.filter(function(log,i){
            return log._id !== logId
          })
        }
        if(!scheduleDate){
          scheduleDate = this.getDefaultScheduleDate(response)
        }
        getDistributionGroupTargeted({
          listId: listId,
          scheduleDate: moment(scheduleDate).local()
          .format("YYYY-MM-DD")
        }, function(err, totalContacts){
          this.setState({
            scheduled: response,
            scheduleDate: scheduleDate,
            targetedContacts: totalContacts
          }, function(){
              this.validate()
            })
        }.bind(this))
      }
    }.bind(this))
  }

  getTemplate(templateId){
    this.setState({
      loading: true
    })
    if(templateId === 0){
      this.setState({
        subject: '',
        userMessage: '',
        domain: '',
        pages: [],
        template: null,
        loading: false
      })
    }else{
      getEmailTemplate({templateid: templateId}, function(err, response){
        if(err){
          console.log(err)
        }
        if(response){
          if(response.template){
            if(response.template.errorMessage){
              this.setState({errorMessage: response.template.errorMessage, loading: false})
            }
            this.setTemplate(response.template, function(){
              this.validate()
            }.bind(this))
          }
        }
      }.bind(this))
    }
  }

  hasTemplateChanged(){
    let returnChanged = false
    if(this.state.loadedTemplate){
      if(this.state.loadedTemplate.subject !== this.state.subject){
        returnChanged = true
        return returnChanged
      }
      if(this.state.loadedTemplate.userMessage !== this.state.userMessage){
        returnChanged = true
        return returnChanged
      }
      if(this.state.loadedTemplate.domain !== this.state.domain){
        returnChanged = true
        return returnChanged
      }
      if(this.state.loadedTemplate.pagesShared !== this.state.pages){
        returnChanged = true
        return returnChanged
      }
      if(this.state.loadedTemplate.showUserImage !== this.state.showUserImage){
        returnChanged = true
        return returnChanged
      }
    }
    return returnChanged
  }

  setTemplate(template, callback){
    let useTemplateOverride = false
    if(template.overrideTemplate){
      useTemplateOverride = true
    }
    this.setState({
      subject: template.subject,
      userMessage: template.userMessage,
      domain: template.domain,
      pages: template.pagesShared,
      template: template._id,
      showUserImage: template.showUserImage,
      loadedTemplate: template,
      overrideTemplate: template.overrideTemplate,
      useTemplateOverride: useTemplateOverride,
      loading: false
    }, function(){
      callback()
    })
  }

  validate() {
    var url = '';
    if(this.state.pages[0]){
      url = this.state.pages[0].url
    }
    this.setState({errors: {
      subject: this.state.subject.length < 1,
      url: url.length < 1,
      name: this.state.campaignName.length < 1,
      date: this.state.dateError.length,
      targetedContacts: this.state.targetedContacts <= 0
    }}, function(){
      this.isFormValid()
    })
  }

  isFormValid(){
    let isValid = true;
    Object.keys(this.state.errors).forEach(function(key, index){
      if(this.state.errors[key] === true){
        isValid = false
      }
    }.bind(this))
    this.setState({isFormValid: isValid})
    if(this.hasTemplateChanged()){
      this.setState({
        template: null
      })
    }
  }

  handleUpdate(event) {
    if(event.target.name === 'userMessage'){
      event.target.value = event.target.value.replace(/<a\b[^>]*>/ig,"").replace(/<\/a>/ig, "")
    }
    var updateObject = {
      [event.target.name]: event.target.value,
    }
    if(event.target.name === 'to'){
      if(event.target.value.split('\n').length > 10){
        var values = event.target.value.split('\n')
        values.splice(10,values.length - 10)
        updateObject.to = values.join('\n')
      }
      updateObject.recipientRows = event.target.value.split('\n').length
    }
    let templateFields = [
      'subject',
      'userMessage'
    ]
    if(templateFields.includes(event.target.name)){
      // updateObject.template = ''
    }
    this.setState(updateObject, function(){
      this.validate()
    })
  }

  handleSubmit() {
    var updateMessage = function(text) {
      this.setState({ submitError: text })
    }.bind(this)
    var user = getCurrentUser()
    let post = {
      name: this.state.campaignName,
      from: user.email,
      listId: this.state.listId,
      listName: this.state.selectedList.Name,
      subject: this.state.subject,
      userMessage: this.state.userMessage,
      showUserImage: this.state.showUserImage,
      domain: this.state.domain,
      pages: this.state.pages,
      template: this.state.template,
      overrideTemplate: this.state.overrideTemplate,
      scheduled: moment(this.state.scheduleDate).toDate(),
      followUp: moment(this.state.followUpDate).toDate()
    }
    if(this.state.actionText === 'UPDATE CAMPAIGN'){
      post._id = this.state.campaignId
      updateCampaign(post, function(err, data){
        updateMessage(err)
      })
    }else{
      sendCampaign(post, function(message) {
        updateMessage(message)
      })
    }
  }

  featureChange(value, index) {
    let currentState = {...this.state}
    checkFeatureChange(value, currentState, index, this.state.siteIndex, function(updateObject){
      console.log(updateObject)
      this.setState(updateObject, function(){
        this.validate()
      })
    }.bind(this))
  }

  imageUpdate = (url, index) => {
    this.setState(prevState => {
      const pages = [...prevState.pages]
      pages[index].imageUrl = url
      return {pages:pages}
    })
  }

  updatePageMessage = (value, index) => {
    value = value.replace(/<a\b[^>]*>/i,"").replace(/<\/a>/i, "")
    this.setState(prevState => {
      const pages = [...prevState.pages]
      if(pages[index]){
        pages[index].userMessage = value
      }
      return {pages:pages}
    })
  }

  getInitvalue = (index) => {
    if(this.state.pages){
      if(this.state.pages[index]){
        return this.state.pages[index].url
      }
    }
  }

  onListChange = (group) => {
    this.setState({
      loading: true
    })
    getDistributionGroupTargeted({
      listId: group.Id,
      scheduleDate: moment(this.state.scheduleDate).local()
      .format("YYYY-MM-DD")
    }, function(err, totalContacts){
      this.setState({
        listId: group.Id,
        selectedList: group,
        targetedContacts: totalContacts,
        loading: false
      })
    }.bind(this))
  }

  onDateChange = (scheduleDate) => {
    this.setState({
      loading: true
    })
    getDistributionGroupTargeted({
      listId: this.state.listId,
      scheduleDate: moment(scheduleDate).local()
      .format("YYYY-MM-DD")
    }, function(err, totalContacts){
      var error = ''
      if(moment(scheduleDate).isBefore()){
        error = 'Scheduled Date occurs in the past.'
      }
      if(moment(scheduleDate).isAfter(this.state.followUpDate)){
        error = 'Follow-up Date is before the scheduled date.'
      }
      // if(this.checkAlreadyScheduled(scheduleDate)){
      //   error = 'Another campaign is already scheduled on this date. Please select another date.'
      // }
      this.setState({
        scheduleDate: scheduleDate,
        dateError: error,
        targetedContacts: totalContacts,
        loading: false
      }, function(){
        this.validate()
      })
    }.bind(this))
  }

  onFollowUpDateChange = (followUpDate) => {
    this.setState({
      loading: true
    })
    var error = ''
    if(moment(followUpDate).isBefore(this.state.scheduleDate)){
      error = 'Follow-up Date is before the scheduled date.'
    }
    if(moment(followUpDate).isBefore()){
      error = 'Follow-Up Date occurs in the past.'
    }

    // if(this.checkAlreadyScheduled(scheduleDate)){
    //   error = 'Another campaign is already scheduled on this date. Please select another date.'
    // }
    this.setState({
      followUpDate: followUpDate,
      dateError: error,
      loading: false
    }, function(){
      this.validate()
    })
  }

  checkAlreadyScheduled = (scheduleDate, scheduled) => {
    let returnVal = false
    if(!scheduled){
      scheduled = this.state.scheduled
    }
    scheduled.forEach((item, i) => {
      if(moment(item.scheduled).local().format("YYYY-MM-DD") === moment(scheduleDate).local().format("YYYY-MM-DD")){
        returnVal = true
        return returnVal
      }
    });
    return returnVal
  }

  checkValidDate = (currentDate) => {
    var checkDate = currentDate.toDate()
    // return (!this.checkAlreadyScheduled(checkDate) && moment(checkDate).add(1,'days').isAfter())
    return (moment(checkDate).add(1,'days').isAfter())
  }

  render() {
    let mailSent = <div><img src={done} alt="You did it!" />
    <p>High Five! Your campaign has been scheduled for {Moment(this.state.scheduleDate)
    .local()
    .format("MM-DD-YYYY hh:mm:ss a")} to the <b>{this.state.selectedList.Name}</b>&nbsp;list with <b>{this.state.targetedContacts}</b> targetable member(s).</p>
    </div>

    if(this.state.errorMessage){
      mailSent = <div><img src="https://media.giphy.com/media/mG8ys6DrOPk7m/giphy.gif" alt="Darn!" /><p>Something seems to have gone wrong in sending your email.</p><p>{this.state.submitError}</p></div>
    }
    return (
      <Layout>
      <View title={this.state.pageTitle}>
      <div className="row col-xs-12">
      <p>First, name your campaign to be an easily identifiable.</p>
      <p>Then, select a distribution list from the drop-down menu. NOTE: each list's name references its members and how many can be targeted at any scheduled campaign's date/time.</p>
      <p>Now, Pick a date and time from the interactive calendar. Some dates might be blocked out, indicating that one of your campaigns has already been scheduled that day. Selecting different date and time will automatically refresh the number of targetable contacts within the selected distribution list.</p>
      <p>Either select an email template from the drop-down menu or start composing your email following the same process as creating a single email. NOTE: Your email content continues to be editable after being selected – both for template and manual composition.</p>
      <p>Finally, preview your email campaign before scheduling it. It's that simple!</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12">
          <WaitLoader loading={this.state.loading} />
          <div className="box" style={{
            margin: '0px auto'
          }}>
          <MultiStepForm
            formValid={this.state.isFormValid}
          >
            <FormTab label="Compose" key="1" nextLabel="PREVIEW">
              <Form
                handleUpdate={e => this.handleUpdate(e)}
                handleSubmit={e => this.handleSubmit(e)}
                campaignName={this.state.campaignName}
                subject={this.state.subject}
                userMessage={this.state.userMessage}
                listId={this.state.listId}
                emailValidationMessage={this.state.emailValidationMessage}
                scheduleDate={this.state.scheduleDate}
                followUpDate={this.state.followUpDate}
                onListChange={this.onListChange}
                onDateChange={this.onDateChange}
                onFollowUpDateChange={this.onFollowUpDateChange}
                dateError={this.state.dateError}
                targetedContacts={this.state.targetedContacts}
                checkValidDate={this.checkValidDate}
                showUserImage={this.state.showUserImage}
                templateId={this.state.template}
                onTemplateChange={value => this.getTemplate(value)}
              />
              <label className={styles[`form__label`]}>
                First Feature
                <URLAutocomplete
                  siteIndex={this.state.siteIndex}
                  domain={this.state.domain}
                  initValue={this.getInitvalue(0)}
                  onChangeFunction={value => this.featureChange(value, 0)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[0] ? (
              <>
              <Slider images={this.state.pages[0].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[0].imageUrl} index="0" />
              <div style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
              <RichTextEditor
                onUpdate={(value, index) => this.updatePageMessage(value, index)}
                body={this.state.pages[0].userMessage}
                index={0}
                />
              </div>
              </>) : null}
              <label className={styles[`form__label`]} style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
                Second Feature
                <URLAutocomplete
                  siteIndex={this.state.siteIndex}
                  domain={this.state.domain}
                  initValue={this.getInitvalue(1)}
                  onChangeFunction={value => this.featureChange(value, 1)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[1] ? (
                <div style={{
                  display: (this.state.useTemplateOverride ? 'none' : 'block')
                }}>
                <Slider images={this.state.pages[1].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[1].imageUrl} index="1" />
                <RichTextEditor
                  onUpdate={(value, index) => this.updatePageMessage(value, index)}
                  body={this.state.pages[1].userMessage}
                  index={1}
                  />
                </div>) : null}
              <label className={styles[`form__label`]} style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
                Third Feature
                <URLAutocomplete
                  siteIndex={this.state.siteIndex}
                  domain={this.state.domain}
                  initValue={this.getInitvalue(2)}
                  onChangeFunction={value => this.featureChange(value, 2)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[2] ? (
              <div style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
              <Slider images={this.state.pages[2].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[2].imageUrl} index="2" />
              <RichTextEditor
                onUpdate={(value, index) => this.updatePageMessage(value, index)}
                body={this.state.pages[2].userMessage}
                index={2}
                />
              </div>) : null}
            </FormTab>
            <FormTab label="PREVIEW" key="2" nextLabel={this.state.actionText} prevLabel="BACK">
              <EmailPreview
                to={this.state.to}
                subject={this.state.subject}
                userMessage={this.state.userMessage}
                showUserImage={this.state.showUserImage}
                domain={this.state.domain}
                pages={this.state.pages}
                overrideTemplate={this.state.overrideTemplate}
              />
              <p>If the email preview looks good, go on and click 'Schedule Campaign.' Need to make changes? Click 'Back' to edit your email campaign.</p>
            </FormTab>
            <FormTab label="SCHEDULE" key="3" nextLabel=""
              nextAction={this.handleSubmit.bind(this)}
              lastStep={(e)=>{
                navigate('/email/campaign/scheduled')
              }}
              lastStepLabel="VIEW YOUR SCHEDULED CAMPAIGNS">
              {mailSent}
            </FormTab>
          </MultiStepForm>
          <label>{this.state.errorMessage}</label>
          </div>
        </div>
        </div>
      </View>
      </Layout>
    )
  }
}


export default EmailCampaign

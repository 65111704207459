import React from 'react'
import DistributionListSelect from '../DistributionListSelect'
import TemplateListSelect from '../Email/TemplateListSelect'
import ReactDateTime from 'react-datetime'
import * as styles from './form.module.css'
import { navigate } from '@reach/router'
import RichTextEditor from '../Email/RichTextEditor'
import BestPracticeLink from './best-practices'
import ShortCodesLink from './shortcodes'

const EmailCampaign = (({
  handleSubmit,
  handleUpdate,
  handleDateChange,
  onListChange,
  listId,
  onDateChange,
  onTemplateChange,
  onFollowUpDateChange,
  templateId,
  campaignName,
  scheduleDate,
  followUpDate,
  url,
  url2,
  url3,
  subject,
  userMessage,
  showUserImage,
  errors,
  dateError,
  targetedContacts,
  checkValidDate,
  history }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
      navigate(`/email-campaign`)
    }}
  >
    <label className={styles[`form__label`]}>
      Name
      <input
        className={styles[`form__input`]}
        type="text"
        name="campaignName"
        value={campaignName}
        placeholder="Enter a Descriptive Name for the Campaign"
        onChange={handleUpdate}
      />
    </label>
    <label className={styles[`form__label`]}>
      Distribution List
      <DistributionListSelect onChange={onListChange} listId={listId} />
      {targetedContacts >= 0 &&
        <p className="info">{targetedContacts} targeted on this date</p>
      }
    </label>
    <label className={styles[`form__label`]}>
      Scheduled Date/Time
      <ReactDateTime
        value={scheduleDate}
        onChange={onDateChange}
        isValidDate={checkValidDate}
      />
      {dateError.length > 0 &&
        <p className="error">Error: {dateError}</p>
      }
    </label>
    <label className={styles[`form__label`]}>
      Follow-Up Date
      <ReactDateTime
        value={followUpDate}
        onChange={onFollowUpDateChange}
        isValidDate={checkValidDate}
        timeFormat={''}
      />
      {dateError.length > 0 &&
        <p className="error">Error: {dateError}</p>
      }
    </label>
    <label className={styles[`form__label`]} htmlFor="template-select">
      Select an Email Template
      <TemplateListSelect id="template-select" onChange={onTemplateChange} templateId={templateId}/>
    </label>
    <label className={styles[`form__label`]} htmlFor="subject-line">
      Subject Line
      <input
        id="subject-line"
        className={styles[`form__input`]}
        type="text"
        name="subject"
        value={subject}
        placeholder="TYPE AN ENTICING SUBJECT LINE"
        onChange={handleUpdate}
      />
    </label>
    <BestPracticeLink />
    <div className='row' style={{
      height: '1.5rem'
    }}>
      <div className='col-xs-6'>
        <label className={styles[`form__label`]} htmlFor="userMessage">
          Intro Message
        </label>
      </div>
      <div className='col-xs-6' style={{
        textAlign: 'right'
      }}>
        <ShortCodesLink />
      </div>
    </div>
    <RichTextEditor
    id="userMessaage"
    name="userMessage"
    body={userMessage}
    placeholder="TYPE AN ENGAGING INTRO MESSAGE"
    onUpdate={(value) => handleUpdate({target: {
      name: 'userMessage',
      value: value
    }})}
    />
    <label className={styles[`form__label`] + ' ' + styles[`for__checkbox`]}>
    Show User Image (if available)
    <input
    className={styles[`form__input__checkbox`]}
    type="checkbox"
    name="showUserImage"
    checked={showUserImage}
    onChange={(e) => handleUpdate({target: {
          name: 'showUserImage',
          value: e.target.checked
        }})}
    />
    </label>
  </form>
))

export default EmailCampaign

import React from 'react'
import { getEmailTemplates } from '../../../utils/email'
import * as style from '../../Form/form.module.css'

class TemplateListSelect extends React.Component {
  constructor() {
    super()
    this.state = {
      templates: [],
      selectedTemplateId: 0
    }
  }

  componentDidMount() {
    getEmailTemplates(function(err, templates){
      if(err){
        console.log(err)
      }
      console.log(templates)
      if(templates){
        if(templates.templates){
          if(templates.templates.length){
            this.setState({
              templates: templates.templates
            })
          }
        }
      }
    }.bind(this))
  }

  onChange = (event) => {
    this.props.onChange(event.target.value)
  }

  render(){
    let templates = []
    if(this.state.templates){
      templates = this.state.templates
    }
    let templateItems = templates.map(function(template, index){
      return(
        <option key={template._id} value={template._id}>{template.subject}</option>
      )
    })
    return(<select className={style['form__select']} onChange={(e)=>{this.onChange(e)}} value={this.props.templateId}>
    <option key={0} value={0}>--None--</option>
    {templateItems}
    </select>)
  }

}

export default TemplateListSelect
